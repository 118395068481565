import dynamic from 'next/dynamic';
import { usePathname, useRouter } from 'next/navigation';
import { forwardRef, Suspense, useEffect, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio';

import { LoadingIndicator } from '../loading-indicator';
import { DialogClose, DialogContent, DialogOverlay, DialogPortal, DialogRoot } from './Dialog';
import { closeDialog, dialogManager, DialogType, restoreDialog } from './model';

const ImpersonationForm = dynamic(() => import('@/forms/impersonation-form'));
const KybKycPassForm = dynamic(() => import('@/forms/kyb-kyc-form'));
const RefundPaymentForm = dynamic(() => import('@/forms/refund-payment-form'));
const PaymentDetails = dynamic(() => import('@/blocks/payment-details'));
const SubscriberList = dynamic(() => import('@/blocks/subscribers'));
const SubscriberForm = dynamic(() => import('@/forms/add-or-amend-subscriber-form'));

type Props = {
  type: DialogType;
  details: Record<string, unknown>;
};

const preventDefault = (event: Event) => event.preventDefault();

const GlobalDialogContent: React.FC<Props> = ({ type }) => {
  switch (type) {
    case DialogType.IMPERSONATION: {
      return <ImpersonationForm />;
    }
    case DialogType.KYB_KYC_PASS: {
      return <KybKycPassForm />;
    }
    case DialogType.REFUND_PAYMENT: {
      return <RefundPaymentForm />;
    }
    case DialogType.PAYMENT_DETAILS: {
      return <PaymentDetails />;
    }
    case DialogType.SUBSCRIBER_LIST: {
      return <SubscriberList />;
    }
    case DialogType.ADD_AMEND_SUBSCRIBER: {
      return <SubscriberForm />;
    }
    case DialogType.NONE: {
      return null;
    }
    default: {
      return null;
    }
  }
};

export const GlobalDialog = forwardRef(() => {
  const {
    open,
    type,
    details,
    stack,
    showCloseIcon,
    closeOnClickOutside,
    closeOnEscapeKeyDown,
    className,
    closeCallback,
  } = useSnapshot(dialogManager);
  const router = useRouter();
  const pathname = usePathname();
  const mountedRef = useRef(false);

  const handleDialogClose = () => {
    if (stack.length > 0) {
      restoreDialog();
    } else {
      closeDialog();
    }
    setTimeout(closeCallback, 150);
  };

  useEffect(() => {
    if (!mountedRef.current) {
      mountedRef.current = true;
      return;
    }
    if (open) return;
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.delete('dialog');
    urlSearchParams.delete('t');
    router.replace([pathname, urlSearchParams.toString()].filter(Boolean).join('?'), {
      scroll: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, pathname]);

  return (
    <DialogRoot open={open} onOpenChange={handleDialogClose}>
      <DialogPortal>
        <DialogOverlay />
        <DialogContent
          onEscapeKeyDown={closeOnEscapeKeyDown ? undefined : preventDefault}
          onPointerDownOutside={closeOnClickOutside ? undefined : preventDefault}
          onInteractOutside={closeOnClickOutside ? undefined : preventDefault}
          className={twMerge('min-h-[300px]', className)}
        >
          <DialogClose hidden={!showCloseIcon} />
          <Suspense fallback={<LoadingIndicator />}>
            <GlobalDialogContent type={type} details={details} />
          </Suspense>
        </DialogContent>
      </DialogPortal>
    </DialogRoot>
  );
});

GlobalDialog.displayName = 'GlobalDialog';
